<template>
  <div class="Phonogram">
    <downBtn
      :topShow="true"
      :isWx="isWx"
      @down="download"
      @wechatShow="wechatShow"
    ></downBtn>

    <div class="box">
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="['box_item', nclass == index ? 'box_cass' : '']"
        @click="btncass(index, item)"
      >
        {{ item.name }}
        <!-- <a
          :href="`#${item.Jump}`"
          :class="['box_item', nclass == index ? 'box_cass' : '']"
          >{{ item.name }}</a
        > -->
      </div>
    </div>

    <div class="so">
      <div class="voiceless_consonant">
        <div class="so_box">
          <div class="so_tab" id="voiceless_consonant">清音</div>
        </div>
        <div class="so_s">
          <div class="so_s1" v-for="(item, index) in solist" :key="index">
            {{ item }}
          </div>
        </div>
        <div class="so_b" v-for="(item, index) in dataArr[0]" :key="index">
          <div class="so_item">{{ item.id }}</div>
          <div
            v-for="(v, i) in item.chidern"
            :key="i"
            class="so_c"
            @click="btn(v)"
          >
            <div class="so_ss" v-if="v">{{ v.hiraganaWord }}</div>
            <div class="so_ss" v-else></div>
            <div class="so_dd" v-if="v">{{ v.katakanaWord }}</div>
            <div class="so_ss" v-else></div>
          </div>
        </div>
      </div>

      <div class="dullness">
        <div class="so_box">
          <div class="so_tab" id="dullness">浊音</div>
        </div>
        <div class="so_s">
          <div class="so_s1" v-for="(item, index) in solist" :key="index">
            {{ item }}
          </div>
        </div>
        <div class="so_b" v-for="(item, index) in dataArr[1]" :key="index">
          <div class="so_item">{{ item.id }}</div>
          <div
            v-for="(v, i) in item.chidern"
            :key="v.id"
            class="so_c"
            @click="btn(v)"
          >
            <div class="so_ss">{{ v.hiraganaWord }}</div>
            <div class="so_dd">{{ v.katakanaWord }}</div>
          </div>
        </div>
      </div>
      <div class="inflexible_sound">
        <div class="so_box">
          <div class="so_tab" id="inflexible_sound">拗音</div>
        </div>
        <div class="so_s">
          <div class="so_s2" v-for="(item, index) in solistsd" :key="index">
            {{ item }}
          </div>
        </div>
        <div class="so_b1" v-for="(item, index) in dataArr[2]" :key="index">
          <div class="so_item">{{ item.id }}</div>
          <div
            v-for="(v, j) in item.chidern"
            :key="j"
            class="so_c"
            @click="btn(v)"
          >
            <div class="so_ss">{{ v.hiraganaWord }}</div>
            <div class="so_dd">{{ v.katakanaWord }}</div>
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="show" position="bottom" :style="{ height: '60%' }">
      <div class="popup_bm">
        <div class="popup_bm1">
          <span class="popup_2">{{ objdata.rome }}</span>
          <span class="popup_1">罗马音</span>
        </div>
        <div class="popup_bm2" @click="PlayConversation">
        <img src="@/assets/Vector (4).png" alt="" />
        </div>
      </div>
      <div class="popup_size">
        <div class="popup_box">
          <div class="popup_1">
            {{ objdata.hiraganaWord }}
          </div>
          <div class="title">平假名</div>
        </div>
        <div class="popup_box">
          <div class="popup_1">
            {{ objdata.katakanaWord }}
          </div>
          <div class="title">片假名</div>
        </div>
      </div>

      <div class="association" v-if="objdata.hiraganaInterestingImage">
        <div class="association_title">联想建议</div>
        <div class="association_item">
          <img :src="objdata.hiraganaInterestingImage" alt="" />
          <div class="association_d">{{ objdata.hiraganaInterestingText }}</div>
        </div>
      </div>
      <div class="pronuntion">
        <div class="pronuntion_title">发音技巧</div>
        <div class="pronuntion_item">
          {{ objdata.pronounceSkill }}
        </div>
      </div>

      <audio id="myaudio" class="myaudio" :src="objdata.audio"></audio>
    </van-popup>
  </div>
</template>

<script>
import downBtn from "@/components/downBtn.vue";
import mixin from "@/utils/mixin";
import { Syllable } from "@/api/teacher.js";
export default {
  mixins: [mixin],
  name: "curriculumFree",
  components: {
    downBtn,
  },
  data() {
    return {
      show: false,
      list: [
        { name: "清音", Jump: "voiceless_consonant" },
        { name: "浊音", Jump: "dullness" },
        { name: "拗音", Jump: "inflexible_sound" },
      ],
      nclass: 0,
      objdata: {},
      solist: [" ", "a", "i", "u", "e", "o"],
      solistsd: [" ", "ya", "yu", "yo"],
      arr: [],
      dataArr: [
        [
          { id: "-", chidern: [] },
          { id: "k", chidern: [] },
          { id: "s", chidern: [] },
          { id: "t", chidern: [] },
          { id: "n", chidern: [] },
          { id: "h", chidern: [] },
          { id: "m", chidern: [] },
          { id: "y", chidern: [] },
          { id: "r", chidern: [] },
          { id: "w", chidern: [] },
          { id: " ", chidern: [] },
        ],
        [
          { id: "g", chidern: [] },
          { id: "z", chidern: [] },
          { id: "d", chidern: [] },
          { id: "b", chidern: [] },
          { id: "p", chidern: [] },
        ],
        [
          { id: "k", chidern: [] },
          { id: "s", chidern: [] },
          { id: "t", chidern: [] },
          { id: "n", chidern: [] },
          { id: "h", chidern: [] },
          { id: "m", chidern: [] },
          { id: "r", chidern: [] },
          { id: "p", chidern: [] },
          { id: "g", chidern: [] },
          { id: "g", chidern: [] },
          { id: "b", chidern: [] },
        ],
      ],
    };
  },
  created() {
    for (let i = 0; i < 3; i++) {
      this.Syllable({ type: i });
    }
  },
  mounted() {
    this.list[0].Jump = document.querySelector(".voiceless_consonant").offsetTop;
    this.list[1].Jump = document.querySelector(".dullness").offsetTop - 120;
    this.list[2].Jump = document.querySelector(".inflexible_sound").offsetTop - 240;
    document.querySelector(".so").addEventListener("scroll", () => {
      console.log(this.arr);
      let top = document.querySelector(".so").scrollTop;
      console.log(top);
      this.list.forEach((item, index) => {
        if (top >= item.Jump) {
          this.nclass = index;
        }
      });
    });
  },
  methods: {
    PlayConversation() {
      let myaudio = document.querySelector("#myaudio");
      myaudio.play();
    },
    btn(val) {
      if (val) {
        this.show = true;
        this.objdata = val;
        console.log(val);
      }
    },
    btncass(n, val) {
      this.nclass = n;
      console.log(n);
      document.querySelector(".so").scrollTop = val.Jump;
    },
    async Syllable(params) {
      let data = await Syllable(params);
      data.data.forEach((item, index) => {
        let arr = [];
        item.japaneseSyllableDetails.forEach((japanese) => {
          if (params.type != 2) {
            let i = this.solist.indexOf(
              japanese.rome.substr(japanese.rome.length - 1, 1)
            );
            if (i !== -1) {
              arr[i - 1] = japanese;
            }
          } else {
            for (let s = 0; s < this.solistsd.length; s++) {
              let i = this.solistsd[s].indexOf(
                japanese.rome.substr(japanese.rome.length - 1, 1)
              );
              if (i !== -1) {
                arr[s - 1] = japanese;
              }
            }
          }
        });
        if (arr.length > 0) {
          this.dataArr[params.type][index].chidern = arr;
        } else {
          let result = new Array(5);
          result[0] = item.japaneseSyllableDetails[0];
          this.dataArr[params.type][index].chidern = result;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  justify-content: center;
  margin-top: 55px;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 6px;
  .box_item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 79px;
    height: 34px;
    font-size: 14px;
    border: 1px solid #eeeeee;
    color: #333333;

    &:nth-child(2) {
      border-left: none;
      border-right: none;
    }
    &:nth-child(1) {
      border-radius: 4px 0px 0px 4px;
    }
    &:nth-child(3) {
      border-radius: 0px 4px 4px 0px;
    }
    &.box_cass {
      background: #ff9a84;
      color: #ffffff;
    }
  }
}
.so {
  height: calc(100vh - 98px);
  padding-bottom: 20px;
  overflow: auto;
  .so_box {
    text-align: center;
    .so_tab {
      font-size: 20px;
      margin: 16px 0px;
      color: #333333;
    }
  }

  .so_s,
  .so_b {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 19px;

    .so_s1 {
      text-align: center;
      line-height: 41px;
      width: 68px;
      height: 41px;
      background: #ffd5cc;
      font-size: 20px;
      border-left: 1px solid #ffeeea;
      &:nth-child(1) {
        border: none;
        width: 35px;
      }
    }
    .so_s2 {
      text-align: center;
      line-height: 41px;
      width: 100px;
      height: 41px;
      background: #ffd5cc;
      font-size: 20px;
      border-left: 1px solid #ffeeea;
      &:nth-child(1) {
        border: none;
        width: 35px;
      }
    }
    .so_item {
      text-align: center;
      line-height: 42px;
      height: 42px;
      width: 35px;
      font-size: 20px;
      background: #ffd5cc;
      border-bottom: 2px solid #ffeeea;
      border-top: 2px solid #ffeeea;
    }
    .so_c {
      text-align: center;
      line-height: 42px;
      width: 68px;
      height: 42px;
      background: #f5f5f5;

      border: 2px solid #ffeeea;
      // border: 1px solid red;
      display: flex;
      justify-content: center;
      .so_ss,
      .so_dd {
        line-height: 42px;
        font-size: 20px;
      }
      .so_ss {
        color: #ff9a84;
      }
    }
  }

  .so_b1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 19px;

    .so_s1 {
      text-align: center;
      line-height: 41px;
      width: 68px;
      height: 41px;
      background: #ffd5cc;
      font-size: 20px;
      border-left: 1px solid #ffeeea;
      &:nth-child(1) {
        border: none;
        width: 35px;
      }
    }
    .so_s2 {
      text-align: center;
      line-height: 41px;
      width: 100px;
      height: 41px;
      background: #ffd5cc;
      font-size: 20px;
      border-left: 1px solid #ffeeea;
      &:nth-child(1) {
        border: none;
        width: 35px;
      }
    }
    .so_item {
      text-align: center;
      line-height: 42px;
      height: 42px;
      width: 35px;
      font-size: 20px;
      background: #ffd5cc;
      border-bottom: 2px solid #ffeeea;
      border-top: 2px solid #ffeeea;
    }
    .so_c {
      text-align: center;
      line-height: 42px;
      width: 100px;
      // height: 42px;
      background: #f5f5f5;

      border: 2px solid #ffeeea;
      // border: 1px solid red;
      display: flex;
      justify-content: center;
      .so_ss,
      .so_dd {
        line-height: 42px;
        font-size: 20px;
      }
      .so_ss {
        color: #ff9a84;
      }
    }
  }
}

.van-popup--bottom {
  border-radius: 10px 10px 0 0;
  .popup_bm {
    height: 50px;
    width: 100%;
    background: #ff9a84;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    box-sizing: border-box;
    .popup_bm1 {
      display: flex;
      // align-items: center;
      // width: 100px;
      // height: 50px;
      .popup_2 {
        font-size: 35px;
        color: #f5f5f5;
        margin-right: 15px;
      }
      .popup_1 {
        margin: 20px 0 0;
        font-size: 12px;
        color: #f5f5f5;
      }
    }
    img {
      height: 20px;
    }
    .popup_bm2 {
      width: 70px;
      height: 50px;

     
      text-align: center;
      line-height: 50px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .popup_size {
    height: 100px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding: 0px 50px 10px;
    // padding-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
    .popup_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      &:nth-child(1) {
        .popup_1 {
          color: #ff9a84;
        }
      }
      .title {
        font-size: 16px;
        margin-top: 10px;
      }
    }
    .popup_1 {
      width: 70px;
      height: 70px;
      background: url("~@/assets/icon_field_word_bg.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 70px;
      font-size: 28px;
    }
  }
}

.pronuntion {
  padding: 0px 15px;
  .pronuntion_title {
    font-size: 20px;
    margin-top: 15px;
    font-weight: 700;
  }
  .pronuntion_item {
    margin-top: 10px;
    font-size: 14px;
    line-height: 26px;
  }
}

.association {
  padding: 0px 15px;
  .association_title {
    margin-top: 10px;
    font-size: 20px;
    margin-top: 15px;
    font-weight: 700;
  }
  .association_item {
    margin-top: 15px;
    display: flex;
    img {
      flex-shrink: 0;
      width: 150px;
      height: 73px;
      margin-right: 10px;
    }
    .association_d {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>